import { classNames } from 'shared/lib/classNames/classNames';
import { IOffice } from 'shared/models/ContactModel';
import cls from './OfficeCard.module.css';

interface OfficeCardProps {
    className: string;
    data: IOffice;
}

export const OfficeCard: React.FC<OfficeCardProps> = (props) => {
    const { className, data, ...otherProps } = props;

    return (
        <div className={classNames(cls.officeCard, {}, [className])} {...otherProps}>
            <h2 className="text-color-red pb-3 mb-md-4">{data.city}</h2>
                <p className="pb-4">{data.company}</p>
                <p className="pb-4">Адрес: {data.address}</p>
                <p>Телефон: {data.phone}</p>
                <p>Факс: {data.fax}</p>
            <p>E-mail:&nbsp;<a href="mailto:scr@scr-broker.com">{data.email}</a></p>
        </div>
 );
}