import { useParams } from 'react-router-dom';
import { classNames } from 'shared/lib/classNames/classNames';
import cls from './ServiceContent.module.css';
import { serviceApi } from 'shared/api/ServiceApi';
interface ServiceContentProps {
    className: string;
}


export const ServiceContent: React.FC<ServiceContentProps> = (props) => {
    const { className } = props;
    const params = useParams()
    const key = params.key

    const {isLoading, data: services} = serviceApi.useFetchAllServicesQuery('')
    const error_message = {
        title: 'Ошибка загрузки',
        content: 'Ошибка загрузки',
        shortcat: ''
    }

    const service = services?.find(({id}) => id===Number(key)) || error_message
    
    return (
    <div className={classNames(cls.serviceContent, {}, ["container pt-5 pb-lg-5", className])}>
        {isLoading &&  <p>Loading...</p> }
        {
           <>
            {/* <h3 className="pb-3 mb-3">{service?.title}</h3> */}
            <div className={cls.content} dangerouslySetInnerHTML={{__html: service.content || '<h1>Произошла ошибка</h1>' }}>
            </div>
           </>
        }
        
    </div>
 );
}