import { useAppSelector } from 'shared/hooks/useAppDispatch/useAppDispatch'
import { BannerWithSlider } from 'widgets/BannerWithSlider'
import { ConsultationRow } from '../../../../widgets/ConsultationRow'
import { MapRow } from '../../../../widgets/MapRow'
import { NewsSlider } from '../../../../widgets/NewsSlider'


export const NewsPage = () => {

  const {news} = useAppSelector(state => state.NewsReducer)

  return (
    <main>
      <BannerWithSlider />
      
      {news.length>0 && <NewsSlider news={news}/> }
      <ConsultationRow />
      <MapRow />
    </main>
  )
}
