import { classNames } from 'shared/lib/classNames/classNames';
import cls from './Arrow.module.css';


export const enum ArrowDirections {
  RIGHT = 'right',
  LEFT = 'left'
}

interface ArrowProps {
  className: string;
  direction: ArrowDirections;
}

export const Arrow: React.FC<ArrowProps> = (props) => {
  const { className, direction } = props;

  return (
    <button 
      className={classNames(
        cls.arrow, 
        {}, 
        [
          className, 
          'bi mr-2 text-muted', 
          `bi-arrow-${direction}-circle`
        ]
      )}
    >
    </button>
 );
}
