import React, { useState } from 'react'
import partner_1  from '../../../../app/img/companies/1.jpg'
import partner_2  from '../../../../app/img/companies/2.jpg'
import partner_3  from '../../../../app/img/companies/3.jpg'
import partner_4  from '../../../../app/img/companies/4.jpg'
import partner_5  from '../../../../app/img/companies/5.jpg'
import partner_6  from '../../../../app/img/companies/6.jpg'
import partner_7  from '../../../../app/img/companies/7.jpg'
import partner_8  from '../../../../app/img/companies/8.jpg'
import partner_9  from '../../../../app/img/companies/9.jpg'
import partner_10  from '../../../../app/img/companies/10.jpg'
import bg_business_woman  from '../../../../app/img/bg_business_woman.png'
import { ConsultationRow } from '../../../../widgets/ConsultationRow'
import { MapRow } from '../../../../widgets/MapRow'
import { ServicesRow } from 'widgets/ServicesRow/ServicesRow'
import { BannerWithSlider } from 'widgets/BannerWithSlider'

import { motion } from 'framer-motion'
import { HomeNews } from 'widgets/HomeNews/ui/HomeNews'
import { useAppDispatch, useAppSelector } from 'shared/hooks/useAppDispatch/useAppDispatch'
import { HomeReviews } from 'widgets/HomeReviews/ui/HomeReviews'
import { PopupSlice } from 'shared/reducers/PopupReducer/PopupSlice'
import { NavLink } from 'react-router-dom'


export const HomePage = () => {

  const [persent, setPersent] = useState(0)
  const [year, setYear] = useState(2023)
  const [experts, setExperts] = useState(0)

  function incPercent(i: any){
    setTimeout(function(){
      setPersent(i)
    }, 20 * i)
  }

  function incExperts(i: any){
    setTimeout(function(){
      setExperts(i)
    }, 1 * i)
  }

  function decYear(i: any, year: any){
    setTimeout(function(){
      setYear(year)
    }, 40 * i)
  }

  const {news} = useAppSelector(state => state.NewsReducer)

  const dispatch = useAppDispatch()

  const {togglePopup} = PopupSlice.actions;

  return (
    <main>
      <BannerWithSlider />
      <ServicesRow />
      <div className="numbers-row container pt-5">
        <div className="about-number row">
          <div className="col-md-4 text-center pb-4">
            <motion.p
              onViewportEnter={()=>{
                let counter = new Date().getFullYear()
                let i = 0
                while(counter>1999){
                  decYear(i, counter)
                  counter--
                  i++
                }
              }}
              >
                с<span className="ml-2">{year}</span>
              </motion.p>
            <p className="mt-n3"><b>На рынке</b></p>
          </div>
          <div className="col-md-4 text-center pb-4">
            <motion.p
              onViewportEnter={()=>{
                let counter = 0
                while(counter<1001){
                  incExperts(counter)
                  counter++
                }
              }}
              >
              <span>+{experts}</span>
            </motion.p>
            <p className="mt-n3"><b>Независимые эксперты</b></p>
          </div>
          <div className="col-md-4 text-center pb-4">
            <motion.p
              onViewportEnter={()=>{
                let counter = 0
                while(counter<51){
                  incPercent(counter)
                  counter++
                }
              }}
            >
                <span>{persent}%</span>
            </motion.p>
            <p className="mt-n3"><b>Гибкая система скидок</b></p>
          </div>
        </div>
      </div>
      <div className="services-block container py-5 mb-lg-5">
        <h2 className="text-color-red col-md-9 col-lg-7 px-0 pb-3">От малого бизнеса до крупнейших корпораций!</h2>
        <p className="sub-title mb-4 pb-md-3">Профессиональный подход к каждому клиенту</p>
        <div className="row">
          <NavLink to='/service/1' className="home__link col-lg-6 pr-lg-2 pb-md-1 mb-2">
            <div className="home-page-card br-10">
              <p className="p-title pb-3 pb-md-4">Страхование имущества<br/>и строительно-монтажных рисков</p>
              <p className="pr-xl-5">Предприятия, коммерческие площади, товарные запасы на скаде, денежная наличность, ломбарды, ювелирные магазины, а также строительство различных объектов и многое другое</p>
            </div>
          </NavLink>
          <NavLink to='/service/2' className="home__link col-lg-6 pl-lg-2 pb-md-1 mb-2">
            <div className="home-page-card br-10">
              <p className="p-title pb-3 pb-md-4">Страхование ответственности</p>
              <p className="pr-xl-5">Ответственность перед третьими лицами, ответственность за качество товаров/услуг,  а также профессиональная и финансовая ответственность.</p>
            </div>
          </NavLink>
          <NavLink to='/service/3' className="home__link col-lg-4 pr-lg-2 pb-md-1 mb-2">
            <div className="home-page-card br-10">
              <p className="p-title pb-3 pb-md-4">Финансовые риски</p>
              <p className="">Ответственность директоров и должностных лиц, комплексное банковское страхование, страхование торговых кредитов.</p>
            </div>
          </NavLink>
          <NavLink to='/service/4' className="home__link col-lg-4 px-lg-2 pb-md-1 mb-2">
            <div className="home-page-card br-10">
              <p className="p-title pb-3 pb-md-4">Страхование кибер рисков</p>
              <p className="">Риски, исходящие от Интернет-пространства. От страховки имущества до последствий киберинцидентов и утечки персональных данных.</p>
            </div>
          </NavLink>
          <NavLink to='/service/5' className="home__link col-lg-4 pl-lg-2 pb-md-1 mb-2">
            <div className="home-page-card br-10">
              <p className="p-title pb-3 pb-md-4">Комплексные программы страхования корпоративных клиентов</p>
              <p className="">Комплексное страхование всех аспектов бизнеса. От сотрудников до имущества и клиентов. Полностью индивидуальные предложения.</p>
            </div>
          </NavLink>
          <NavLink to='/service/6' className="home__link col-lg-3 pr-lg-2 pb-md-1 mb-2 mr-lg-4">
            <div className="home-page-card br-10 mr-lg-n4">
              <p className="p-title pb-3 pb-md-4">Перестрахование</p>
              <p className="">Перестрахование всех видов ответственности, с которыми может столкнуться бизнес.</p>
            </div>
          </NavLink>
          <NavLink to='/service/7' className="home__link col-lg-3 px-lg-2 pb-md-1 mb-2 mr-lg-4">
            <div className="home-page-card br-10 mr-lg-n4">
              <p className="p-title pb-3 pb-md-4">Страхование морских и энергетических рисков</p>
              <p className="">Страхование морских портов и терминалов, а также энергетических компаний</p>
            </div>
          </NavLink>
          <NavLink to='/service/8' className="home__link col-lg-3 px-lg-2 pb-md-1 mb-2 mr-lg-4">
            <div className="home-page-card br-10 mr-lg-n4">
              <p className="p-title pb-3 pb-md-4">Авиационное и космическое страхование</p>
              <p className="">Страхование всех видов ответственности авиационных и космических компаний</p>
            </div>
          </NavLink>
          <div className="col-lg-2 pl-lg-0 pr-xl-0 pb-md-1 mb-2">
            <button onClick={()=>dispatch(togglePopup())} className="red-card-btn btn text-left text-md-center box-shadow w-100 h-100 br-10 ml-lg-2 px-lg-0" type="button" data-toggle="modal" data-target="#consultModal">Оставить заявку<br/> на консультацию</button>
          </div>
        </div>
      </div>
      <ConsultationRow />
      <div className="explication-block container mt-lg-5 pt-5">
        <h2 className="text-color-red col-lg-9 px-0 pb-3">Почему бы не оформить страховку самостоятельно?</h2>
        <p className="sub-title pb-4">Давайте разберемся!</p>
        <p className="h3 pb-2 mb-md-5"><b>Страховка для компании —</b> это всегда сложно и часто дорого.<br/>Разбираться самостоятельно <b>= тратить  ресурсы компании неэффективно.</b></p>
        <div className="box position-relative">
          <div className="home-why-insurance row align-items-center">
            <div className="col-lg-2 py-4">
              <p className="p-title">Скорость</p>
            </div>
            <div className="col-lg-5 pb-2 pb-md-4">
              <div className="box bg-light-gray br-10 p-4 py-lg-5">
                <p className="pl-xl-2">Вам придется отложить все дела, связанные с работой и самостоятельно обращаться во все компании.</p>
              </div>
            </div>
            <div className="col-lg-5 pb-2 pb-md-4">
              <div className="box bg-light-red br-10 p-4 py-lg-5">
                <p className="pl-xl-2">Компания профессионалов подготовит для вас целое досье и расскажет, какой полис лучше выбрать в вашей ситуации.</p>
              </div>
            </div>
          </div>
          <div className="home-why-insurance row align-items-center">
            <div className="col-lg-2 py-4">
              <p className="p-title">Выгодные условия</p>
            </div>
            <div className="col-lg-5 pb-2 pb-md-4">
              <div className="box bg-light-gray br-10 p-4 py-lg-5">
                <p className="pl-xl-2">Вам недоступны скидки и акции для страховых брокеров, поэтому вы не сможете сэкономить средства компании.</p>
              </div>
            </div>
            <div className="col-lg-5 pb-2 pb-md-4">
              <div className="box bg-light-red br-10 p-4 py-lg-5">
                <p className="pl-xl-2">Брокерам доступны специальные условия и скидки. Мы предоставим вам лучшие условия и широкое страховое покрытие.</p>
              </div>
            </div>
          </div>
          <div className="home-why-insurance row align-items-center">
            <div className="col-lg-2 py-4">
              <p className="p-title">Изучение условий страхования</p>
            </div>
            <div className="col-lg-5 pb-2 pb-md-4">
              <div className="box bg-light-gray br-10 p-4 py-lg-4">
                <p className="pl-xl-2 py-lg-3 mb-lg-n1">Придется вникнуть в тонкости вопросов страхования: обсуждения на форумах, статьи и консультации со страховыми компаниями. Вы готовы глубоко погрузитья в тему?</p>
              </div>
            </div>
            <div className="col-lg-5 pb-2 pb-md-4">
              <div className="box bg-light-red br-10 p-4 py-lg-4">
                <p className="pl-xl-2 py-lg-1">Мы уже знаем о рынке страхования все.  Наши эксперты владеют целой базой предложений и знаниями тонкостей страхования, полученными из практики. Мы предложим вам только надежные условия!</p>
              </div>
            </div>
          </div>
          <div className="home-why-insurance row align-items-center">
            <div className="col-lg-2 py-4">
              <p className="p-title">Экономия</p>
            </div>
            <div className="col-lg-5 pb-2 pb-md-4">
              <div className="box bg-light-gray br-10 p-4 py-lg-4">
                <p className="pl-xl-2 py-lg-3 py-xl-4">Компании годами переплачивают за надстройки в страховых полисах и при этом они даже не имеют преимуществ в покрытии!</p>
              </div>
            </div>
            <div className="col-lg-5 pb-2 pb-md-4">
              <div className="box bg-light-red br-10 p-4 py-lg-4">
                <p className="pl-xl-2 py-lg-3 mb-lg-n1">Мы подберем для вас лучшие условия, которые позволят экономить на страховке, получая лучшее покрытие. И наши услуги не будут вам ничего стоить!</p>
              </div>
            </div>
          </div>
          <div className="box-border-red-dashed d-none d-lg-block br-10"></div>
        </div>
      </div>
      <div className="how-we-work-row container pt-5 mb-lg-5">
        <h2 className="text-color-red pb-4 pb-md-5 mb-2 mb-md-0">Как мы работаем?</h2>
        <div className="how-we-work d-flex flex-column flex-md-row align-items-center align-items-md-start justify-content-between flex-nowrap">
          <div className="stage-item">
            <div className="box pb-3">
              <div className="box-circle box-shadow d-flex justify-content-center align-items-center">
                <h2 className="p-3">1</h2>
              </div>
            </div>
            <div className="text-box text-center pt-3">
              <p>Оставьте заявку на<br/>бесплатную<br/>консультацию</p>
            </div>
          </div>
          <span className="icon red-arrow-right my-4 my-md-0 mt-md-5"></span>
          <div className="stage-item">
            <div className="box pb-3">
              <div className="box-circle box-shadow d-flex justify-content-center align-items-center">
                <h2 className="p-3">2</h2>
              </div>
            </div>
            <div className="text-box text-center pt-3">
              <p>Мы направим вам<br/>уточняющие<br/>вопросы</p>
            </div>
          </div>
          <span className="icon red-arrow-right my-4 my-md-0 mt-md-5"></span>
          <div className="stage-item">
            <div className="box pb-3">
              <div className="box-circle box-shadow d-flex justify-content-center align-items-center">
                <h2 className="p-3">3</h2>
              </div>
            </div>
            <div className="text-box text-center pt-3">
              <p>Подбираем идеальные<br/>варианты и<br/>консультируем вас</p>
            </div>
          </div>
          <span className="icon red-arrow-right my-4 my-md-0 mt-md-5"></span>
          <div className="stage-item">
            <div className="box pb-3">
              <div className="box-circle d-flex justify-content-center align-items-center bg-light-red">
                <h2 className="p-3">4</h2>
              </div>
            </div>
            <div className="text-box text-center pt-3">
              <p>Страховка<br/>готова!</p>
            </div>
          </div>
        </div>
      </div>
      {/* Компании */}
      <div className="container pt-5">
        <h2 className="text-color-red col-lg-11 col-xl-9 px-0 pb-4 pb-md-5">В нашей базе более 50 страховых компаний. <span className="text-body">Мы работаем только с лучшими</span></h2>
      </div>
      <div className="get-consultation-block row-item-box d-flex flex-row justify-content-between align-items-center flex-nowrap mb-md-5">
        <img className="d-block ml-3 mr-5" src={partner_1} alt=""/>
        <img className="d-block mx-5" src={partner_2} alt=""/>
        <img className="d-block mx-5" src={partner_3} alt=""/>
        <img className="d-block mx-5" src={partner_4} alt=""/>
        <img className="d-block mx-5" src={partner_5} alt=""/>
        <img className="d-block mx-5" src={partner_6} alt=""/>
        <img className="d-block mx-5" src={partner_7} alt=""/>
        <img className="d-block mx-5" src={partner_8} alt=""/>
        <img className="d-block mx-5" src={partner_9} alt=""/>
        <img className="d-block ml-5 mr-3" src={partner_10} alt=""/>
      </div>
      {/* Компании */}

      <div className="get-consultation-block container pt-5 " style={{maxWidth: '1440px'}}>
        <div className="row">
          <div className="col-lg-6 d-none d-lg-block p-0">
            <img className="d-block w-100" src={bg_business_woman} alt=""/>
          </div>
          <div className="col-lg-6 px-0 py-5 red-bg">
            <div className="start-consultation col-xl-9 px-0 pl-lg-5 pt-xl-4 ml-xl-5">
              <h2 className="text-white px-3 pr-md-0 mb-4">Начнем с консультации?</h2>
              <p className="text-white px-3 pb-4 pb-lg-5 mb-3">Наши услуги бесплатны для вас от первой консультации до получения страховки. Их оплачивает ваша страховая</p>
              <img className="d-block d-lg-none mb-5 w-100" src={bg_business_woman} alt=""/>
              <button onClick={()=>dispatch(togglePopup())} className="red-btn btn border-white text-nowrap br-10 ml-3" type="button">Получить предложение</button>
            </div>
          </div>
        </div>
      </div>

      {/* Наша команда */}
      {/* <div className="container pt-5">
        <h2 className="text-color-red pb-4 pb-md-5">Знакомьтесь с нашей командой экспертов</h2>
      </div>
      <div className="container-fluid our-group px-0 mb-lg-5">
        <div className="cover-box red-bg col-md-7 col-lg-8 col-xl-9 px-lg-5 mx-auto">
          <p className="text-center py-4 py-md-5 px-md-4 px-xl-5">Наши услуги бесплатны для вас от первой консультации до получения страховки. Их оплачивает ваша страховая</p>
        </div>
      </div> */}
      {/* Наша команда */}

      {/* <HomeNews data={news} />
      <HomeReviews /> */}
      <MapRow />
    </main>
  )
}
