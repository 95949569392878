import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/dist/query/react'
import { IService } from 'shared/models/ServicesModel'



export const serviceApi =  createApi({
    reducerPath: 'serviceApi',
    baseQuery: fetchBaseQuery({
        // baseUrl: 'http://localhost:8000/api/v1'
        baseUrl: 'https://api.scr-broker.ru/api/v1'
    }),
    endpoints: (build) => ({
        fetchAllServices: build.query<IService[], any>({
            query: () => ({
                url: '/services_list'
            })
        })
    })
})