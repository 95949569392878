import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { AboutPage } from 'pages/AboutPage';
import { ContactPage } from 'pages/ContactPage';
import { HomePage } from 'pages/HomePage';
import { NewsPage } from 'pages/NewsPage';
import { ReviewsPage } from 'pages/ReviewsPage';
import { ServiceContent, ServicePage } from 'pages/ServicePage';
import { Layout } from 'widgets/Layout/components/Layout/Layout';
import { ContactContent } from 'pages/ContactPage/components/ContactContent/ContactContent';
import { InfoPage } from 'pages/InfoPage/InfoPage';


function App() {
  return (
    <div className="App">
      <Routes>
        <Route path='/' element={< Layout />}>
            <Route path='/' element={<HomePage />}/> 
            <Route path='/service/:key' element={<ServicePage />} >
              <Route path='/service/:key' element={<ServiceContent className='' />} />
            </Route>
            <Route path='/about' element={<AboutPage />}/>
            <Route path='/reviews' element={<ReviewsPage />}/>
            <Route path='/news' element={<NewsPage />}/>
            <Route path='/info' element={<InfoPage />}/>
            <Route path='/contacts' element={<ContactPage />} >
              <Route path='/contacts/:country_slug' element={<ContactContent />} />
            </Route>
        </Route> 
      </Routes>
    </div>
  );
}

export default App;
