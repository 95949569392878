import { createSlice } from '@reduxjs/toolkit';
import { ContactState } from 'shared/models/ContactModel';
import man  from 'app/img/pavel.jpg'


const initialState: ContactState = {
    error: '',
    isLoading: false,
    countries: [
        {
            country: 'Россия',
            slug_country_name: 'russia',
            offices: [
                {
                    address: '123610, Москва, Краснопресненская набережная, д. 12, подъезд 3, офис 710',
                    city: 'Офис в Москве',
                    company: 'ООО "Страховой брокер "Сосьете де Куртаж Ре"',
                    email: 'scr@scr-broker.com',
                    fax: '+7 (495) 967-0218',
                    phone: '+7 (495) 967-0215, +7 (495) 967-0217'                    
                }
            ],
            personals: [
                {
                    fio: 'Резвушкин Павел',
                    email: 'p.rezvushkin@scr-broker.com',
                    position: 'Генеральный директор',
                    photo: man
                },
                {
                    fio: 'Тюрин Владимир',
                    email: 'v.tyurin@scr-broker.com',
                    position: '1-й Заместитель Генерального директора',
                    photo: '...'
                },

            ]
        },
        // {
        //     country: 'Швейцария',
        //     slug_country_name: 'switzerland',
        //     offices: [
        //         {
        //             address: '123610, Швейцария, Краснопресненская набережная, д. 12, подъезд 3, офис 1306',
        //             city: 'Офис в Швейцарии',
        //             company: 'ООО "Страховой брокер "Сосьете де Куртаж Ре',
        //             email: 'scr@scr-broker.com',
        //             fax: '+7 (495) 967-0218',
        //             phone: '+7 (495) 967-0215, +7 (495) 967-0217'                    
        //         }
        //     ],
        //     personals: [
        //         {
        //             fio: 'Другой чел',
        //             email: 'p.rezvushkin@scr-broker.com',
        //             position: 'Генеральный директор',
        //             photo: man
        //         },
        //         {
        //             fio: 'ВТорой другой чел',
        //             email: 'v.tyurin@scr-broker.com',
        //             position: '1-й Заместитель Генерального директора',
        //             photo: man
        //         },

        //     ]

        // },
        // {
        //     country: 'Азейбарджан',
        //     slug_country_name: 'azerbaijan'
        // },
    ]
}


const OfficeSlice = createSlice({
    name: 'contacts',
    initialState: initialState,
    reducers: {

    }
})

export default OfficeSlice.reducer;