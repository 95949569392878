import { createSlice } from '@reduxjs/toolkit';
import { CheckboxState } from './../../models/CheckboxModel';


const initialState: CheckboxState = {
    checked: false
} 


export const CheckboxSlice = createSlice({
    name:'checkbox',
    initialState: initialState,
    reducers: {
        toggleCheckbox(state){
            state.checked = !state.checked
        }, 
    }
})

export default CheckboxSlice.reducer