import axios from "axios"



export const sendMailConsultation = (data: any) => {
    // const url = 'http://127.0.0.1:8000/api/v1/consultation_message'
    const url = 'https://api.scr-broker.ru/api/v1/consultation_message'

    const full_data = {...data, type: 'Консультация' }
    axios.post(url, data=full_data)
        .then((res)=>{console.log(res)})
        .catch((err)=>{console.log(err)})
}