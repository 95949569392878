import { Map, Placemark, YMaps } from '@pbe/react-yandex-maps'
import React from 'react'
import { Link } from 'react-router-dom'
import { classNames } from 'shared/lib/classNames/classNames'
import cls from './MapRow.module.css'


export const MapRow = () => {
  return (
    <div className="map row">
        <div className="dark-bg col-lg-6 px-0">
            <div className="contacts-box col-xl-9 py-lg-3 pl-lg-5 ml-xl-5">
                <h2 className="text-white px-3 py-4 pb-lg-5">Контакты</h2>
                <p className="text-white px-3 pb-4">
                    Адрес: 123610, Москва, Краснопресненская набережная, д. 12, подъезд 3, помещ. IД, ком. 47-49 (офис
                    710)
                    <br /><br />Телефон: +7 (495) 967-0215, +7 (495) 967-0217
                    <br /><br />E-mail:&nbsp;<span style={{textDecoration: 'underline'}}>scr@scr-broker.com</span>
                    <br /><br />Режим работы:
                    <br />Понедельник – Пятница с 09:00 до 18:00
                    <br />Суббота, Воскресение – Выходной.
                    <br /><br />
                    <Link className="text-white text-center d-flex align-items-center" to="/contacts/russia"><b>Наши
                        офисы</b><span className="icon red-arrow-right ml-3"
                        style={{width: '14px', height: '21px'}}></span></Link>
                </p>
            </div>
        </div>
        <div className={classNames( cls.mapContainer ,{}, ['col-lg-6 p-0'])}>
          <YMaps >
            <Map width={'100%'} height={"100%"} defaultState={{ center: [55.754203, 37.556388], zoom: 12 }} >
              <Placemark geometry={[55.754203, 37.556388]}  />
            </Map>
        </YMaps>   
        </div>
                       
    </div>
  )
}
