import { Swiper, SwiperSlide }  from 'swiper/react'
import "swiper/css";
import { Arrow, ArrowDirections } from 'shared/ui/Arrow/Arrow';
import { Navigation, Pagination } from 'swiper';
import { FC, useState } from 'react';
import { Bullet } from 'shared/ui/Bullet/Bullet';

import cls from './NewsSlider.module.css'
import { NewsSlide } from 'features/NewsSlide/ui/NewsSlide';
import { useAppSelector } from 'shared/hooks/useAppDispatch/useAppDispatch';
import { sliceArrOnChunks } from 'shared/lib/sliceArrOnChunks/sliceArrOnChunks';
import { INewsCard } from 'shared/models/NewsModel';
import { classNames } from 'shared/lib/classNames/classNames';


interface NewsSliderProps {
  news: INewsCard[];
}

export const NewsSlider: FC<NewsSliderProps> = (props) => {

  const {news} = props
  const [activeSlide, setActiveSlide] = useState(0)

  const newsSlides = sliceArrOnChunks(news, 6)


  
  const pagination = {
    clickable: true,
    el: ''
  };

  return (
   <>
    <Swiper 
    onSlideChange={(el)=>{setActiveSlide(el.activeIndex)}}
    className={classNames(cls.slider, {}, ['container'])} 
    modules={[Navigation, Pagination]}
    spaceBetween={50}
    slidesPerView={1}
    navigation={
        {
            enabled: true,
            nextEl: '.bi-arrow-right-circle',
            prevEl: '.bi-arrow-left-circle'
        }
    }
    pagination = {
      pagination
    }
    >
    {
      newsSlides.map((data, index)=>(
      <SwiperSlide key={'swiper-news-slide-'+index}>
        <NewsSlide data={data}  key={'news-slide-'+index}/>
      </SwiperSlide>
      ))
    }
    </Swiper> 
    <div className="container-fluid pb-5 px-0">
    <div className="page-number-box d-flex justify-content-center align-items-center mx-auto w-100">
      <Arrow direction={ArrowDirections.LEFT} className="" />
        <div className="">
          {
            newsSlides.map((data, index)=>(
              <Bullet className={activeSlide===index ? cls.active : ''} key={'news-pagination-'+index} onClick={()=>{console.log(activeSlide)}} >
                {index+1}
              </Bullet>
            ))
          }
        </div>
      <Arrow direction={ArrowDirections.RIGHT} className="" />
    </div>
  </div>
   </>  

  )
}
