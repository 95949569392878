import { FC } from 'react'
import { ServicesRow } from 'widgets/ServicesRow/ServicesRow'
import { ConsultationRow } from 'widgets/ConsultationRow'
import { MapRow } from 'widgets/MapRow'
import { Outlet, useParams } from 'react-router-dom'
import { useAppDispatch } from 'shared/hooks/useAppDispatch/useAppDispatch'
import { PopupSlice } from 'shared/reducers/PopupReducer/PopupSlice'
import { classNames } from 'shared/lib/classNames/classNames'
import cls from './ServicePage.module.css'
import { Button, ButtonTheme } from 'shared/ui/Button/Button'
import { serviceApi } from 'shared/api/ServiceApi'
import { ScrollToTopOnMount } from 'shared/lib/ScrollToTopOnMount/ScrollToTopOnMount'

export const ServicePage: FC = () => {


  const dispatch = useAppDispatch()

  const params = useParams()
  const key = params.key
 

  const {isLoading, data: services} = serviceApi.useFetchAllServicesQuery('')

  const service = services && services.find(({id}) => id===Number(key))

  const {togglePopup} = PopupSlice.actions;


  return (
    <main>
      {
        isLoading
        ? <p>...Loading</p>
        :
        <div 
        className={classNames(cls.banner, {}, ["banner-cyber container-fluid px-0 mb-3 mb-md-4"])} 
        style={{
          background: `url(${service?.banner}) center no-repeat`,          
        }}
      >
        <div className={classNames(cls.bannerText, {}, ["row-item-box"])}>
          <h1 className={classNames(cls.title, {}, [])}>{service?.title}</h1>
          <Button className={cls.button} onClick={()=>dispatch(togglePopup())} theme={ButtonTheme.RED} >Получить консультацию</Button>
        </div>
      </div>
      }
      <ServicesRow />
      <Outlet />
    <ConsultationRow />
    <MapRow />
    </main>
  )
}
