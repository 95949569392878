import { IService, ServiceState } from '../../models/ServicesModel';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';


const initialState: ServiceState = {
    error: '',
    isLoading: false,
    services: [ 
    ],
    activeServiceBanner: ''
}

export const ServiceSlice = createSlice({
    name: 'service',
    initialState: initialState,
    reducers: {
        servicesFetching(state){
            state.isLoading = true
        },
        servicesFetchingSuccess(state, action: PayloadAction<IService[]>){
            state.isLoading = false
            state.error = ''
            state.services = action.payload
        },
        servicesFetchingError(state, action: PayloadAction<string>){
            state.isLoading = false
            state.error = action.payload
        },
        setActiveServiceBanner(state, action: PayloadAction<string|undefined>){
            state.activeServiceBanner = action.payload ?? ''
        }
    }
})

export default ServiceSlice.reducer;