import { useLocation, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'shared/hooks/useAppDispatch/useAppDispatch';
import { classNames } from 'shared/lib/classNames/classNames';
import { openPopup } from 'shared/reducers/PopupReducer/PopupActions';
import { PopupSlice, PopupVariants } from 'shared/reducers/PopupReducer/PopupSlice';
import { Button, ButtonTheme } from 'shared/ui/Button/Button';
import cls from './StartPopup.module.css';

interface StartPopupProps {
    className?: string;
}

export const StartPopup: React.FC<StartPopupProps> = (props) => {
    
    const { className } = props;
    const {hidden} = useAppSelector(state => state.PopupReducer) 
    const {togglePopup} = PopupSlice.actions;
    const dispatch = useAppDispatch()

    const consultationForm = document.getElementById('consultationForm')

    



    return (
        <>
            <span className={cls.cross} onClick={()=>dispatch(togglePopup())}></span>
            <h2 className={cls.title}>
                Начнем
                с консультации?
            </h2>
            <p className={cls.description}>
                Наши услуги бесплатны для вас
                от первой консультации до получения страховки
            </p>
            <Button 
                className={cls.firstButton} 
                onClick={()=>{
                    dispatch(togglePopup())
                    consultationForm?.scrollIntoView()
                }}>
                Получить предложение
            </Button>
            <Button className='' onClick={()=>dispatch(openPopup(PopupVariants.FORM))} theme={ButtonTheme.RED}>
                Получить консультацию
            </Button>
        </>
 );
}