import { HTMLAttributes } from 'react';
import { classNames } from 'shared/lib/classNames/classNames';
import cls from './Button.module.css';

export const enum ButtonTheme {
    GRAY = 'gray',
    RED = 'red'
}

interface ButtonProps extends HTMLAttributes<HTMLButtonElement>{
    className: string;
    theme?: ButtonTheme;
    disabled?: boolean; 
}

export const Button: React.FC<ButtonProps> = (props) => {
    const { className, theme = ButtonTheme.GRAY, children, disabled, ...otherProps } = props;

    return (
        <button disabled={disabled} className={classNames(cls.button, {[cls.disabled]: disabled || false}, [cls[theme], className])} {...otherProps}>
            {children}
        </button>
 );
}