import React, { FC } from 'react'
import { ConsultationRow } from '../../../../widgets/ConsultationRow'
import { MapRow } from '../../../../widgets/MapRow'

export const AboutPage: FC = () => {
return (
<main>
  <div className="banner-about-us container-fluid px-0 mb-md-5">
    <div className="container py-5">
      <div className="place-devide py-5 mb-lg-5 my-xl-5"></div>
      <div className="place-devide pt-5 pb-xl-4 mb-lg-5"></div>
    </div>
  </div>
  <div className="container pt-5">
    <h2 className="text-color-red pb-3 mb-md-4">Кто мы</h2>
    <p className="pb-4">
      <span style={{color: '#CC1D0E'}}>«Страховой брокер «Сосьете де Куртаж Ре»</span> – профессиональный страховой и перестраховочный брокер и консультант.
      Независимость, профессионализм, инновационный подход и индивидуальное отношение к каждому клиенту, оперативность,
      широкие международные связи и тонкое знание местных рынков позволяют нам оказывать услуги мирового уровня,
      связанные как, непосредственно, со страхованием, так и с перестрахованием рисков наших клиентов, с учетом местной
      специфики.</p>
    <p className="pb-4"><b>Преимущества работы со страховым брокером</b></p>
    <p className="pb-4"><span style={{color: '#CC1D0E'}}>ООО “Страховой брокер «Сосьете де Куртаж Ре»</span> - молодая, но быстро развивающаяся компания.
      Существуя на российском рынке с 2000 года мы добились значительного роста портфеля заказов, на практике доказав
      нашим клиентам, что страховой и перестраховочный брокер – это не обычный посредник или агент, а Ваш партнер в
      сфере страхования и перестрахования.</p>
    <p className="pb-4">Залог нашего успеха – это процветание наших клиентов.</p>
    <p className="pb-4">Основная наша задача – это обеспечение наших клиентов эффективной страховой и перестраховочной
      защитой, соответствующей высокому международному профессиональному уровню, в сочетании с высоким уровнем сервиса
      для наших клиентов.</p>
    <p className="pb-4">Мы гордимся многолетним сотрудничеством с ведущими страховыми и перестраховочными компаниями
      России, стран бывшего СССР, Ближнего Востока и Азии, среди них такие компании как:</p>
    <p className="pb-4">Ингосстрах, Росгосстрах, Согаз, РЕСО-Гарантия, ВСК, АльфаСтрахование, Согласие, Сбербанк
      страхование, Абсолют Страхование, Энергогарант, Пари, Югория, Гелиос, МАКС, Зетта Страхование, ОСК </p>
  </div>
  <div className="container py-5">
    <h2 className="text-color-red pb-3 mb-md-4">Подразделения</h2>
    <p className="pb-4">
      В настоящее время компания представлена в Российской Федерации, Азербайджане, Республике Казахстан, республике
      Молдова и ОАЭ.
    </p>
    <ul className="pb-4 pl-4">
      <li>ООО «Страховой брокер «Сосьете де Куртаж Ре», Россия</li>
      <li>«Societe De Courtage» Insurance & Reinsurance Brokers, Азербайджан</li>
      <li>Société de Courtage en Réassurances S.A., представительство в республике Казахстан</li>
      <li>Société de Courtage en Réassurances S.A., представительство в республике Молдова</li>
      <li>SCR Insurance Brokers DMCC, Объединенные Арабские Эмират</li>
    </ul>
    <p className="pb-4">
      Менеджмент группы компаний Société de Courtage en Réassurances S.A. обладает необходимым опытом и глубокими знаниями в сфере страхования и перестрахования. Каждый из руководителей зарекомендовал себя как настоящий эксперт в области управления, доказав это отличными показателями эффективности.
    </p>
    
  </div>
  <div className="container pt-5">
    <h2 className="text-color-red pb-3 mb-md-4">Роль и преимущество<br />страхового брокера</h2>
    <p className="pb-4"><b>Зачем вашему бизнесу нужны услуги страхового брокера?</b></p>
    <p className="pb-4">Вы экономите средства компании и ценное рабочее время Ваших сотрудников, возлагая всё общение со
      страховой компанией на нас. Организуя конкурс среди потенциальных страховщиков, мы получим сразу несколько
      предложений для Вас, из которых поможем выбрать наиболее выгодное, полностью отвечающее Вашим требованиям.</p>
    <p className="pb-4"><b>Наши знания, которые могут быть вам полезны</b></p>
    <ul className="pb-4 pl-4">
      <li>Как поведет себя конкретная страховая компания в той или иной ситуации;</li>
      <li>Каково истинное финансовое положение выбранной страховой компании;</li>
      <li>В каких видах страхования она сильна;</li>
      <li>Как можно получить максимальные скидки и широкое страховое покрытие;</li>
      <li>Как не ошибиться при получения убытка.</li>
    </ul>
    <p className="pb-4"><b>В чем заключается выгода от привлечения страхового брокера?</b></p>
    <ul className="pb-4 pl-4">
      <li>Вы обретаете уверенность в том, что договор страхования защищает интересы Вашей компании, а не интересы
        страховщика;</li>
      <li>Вы уверены в том, что получите страховое возмещение вовремя, полностью и без осложнений;</li>
      <li>Если переговоры со страховой компанией зашли в тупик, у Вас будет грамотный адвокат, представляющий Ваши
        интересы</li>
    </ul>
  </div>

  <ConsultationRow />
  <MapRow />
</main>
)
}
