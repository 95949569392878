import { INewsCard } from 'shared/models/NewsModel';
import img_news  from 'app/img/img_news.jpg'
import { createSlice } from '@reduxjs/toolkit';

interface NewsState {
    news: INewsCard[];
    isLoading: boolean;
    error: string;
}


const initialState: NewsState = {
    news: [
        // {
        //     id: 1,
        //     title: 'ЦБ продлил запрет вывода с брокерских счетов недружественных нерезидентов',
        //     date: '12.12.2022',
        //     description: 'Переводы за рубеж средств нерезидентов — как физических, так и юридических лиц — из недружественных стран со счетов брокеров и доверительных управляющих будут ограничены с 1 октября еще на полгода',
        //     img: img_news,
        // },
        // {
        //     id: 2,
        //     title: 'ЦБ продлил запрет вывода с брокерских счетов недружественных нерезидентов',
        //     date: '12.12.2022',
        //     description: 'Переводы за рубеж средств нерезидентов — как физических, так и юридических лиц — из недружественных стран со счетов брокеров и доверительных управляющих будут ограничены с 1 октября еще на полгода',
        //     img: img_news,
        // },
        // {
        //     id: 3,
        //     title: 'ЦБ продлил запрет вывода с брокерских счетов недружественных нерезидентов',
        //     date: '12.12.2022',
        //     description: 'Переводы за рубеж средств нерезидентов — как физических, так и юридических лиц — из недружественных стран со счетов брокеров и доверительных управляющих будут ограничены с 1 октября еще на полгода',
        //     img: img_news,
        // },
        // {
        //     id: 4,
        //     title: 'ЦБ продлил запрет вывода с брокерских счетов недружественных нерезидентов',
        //     date: '12.12.2022',
        //     description: 'Переводы за рубеж средств нерезидентов — как физических, так и юридических лиц — из недружественных стран со счетов брокеров и доверительных управляющих будут ограничены с 1 октября еще на полгода',
        //     img: img_news,
        // },
        // {
        //     id: 5,
        //     title: 'ЦБ продлил запрет вывода с брокерских счетов недружественных нерезидентов',
        //     date: '12.12.2022',
        //     description: 'Переводы за рубеж средств нерезидентов — как физических, так и юридических лиц — из недружественных стран со счетов брокеров и доверительных управляющих будут ограничены с 1 октября еще на полгода',
        //     img: img_news,
        // },
        // {
        //     id: 6,
        //     title: 'ЦБ продлил запрет вывода с брокерских счетов недружественных нерезидентов',
        //     date: '12.12.2022',
        //     description: 'Переводы за рубеж средств нерезидентов — как физических, так и юридических лиц — из недружественных стран со счетов брокеров и доверительных управляющих будут ограничены с 1 октября еще на полгода',
        //     img: img_news,
        // },
        // {
        //     id: 7,
        //     title: 'ЦБ продлил запрет вывода с брокерских счетов недружественных нерезидентов',
        //     date: '12.12.2022',
        //     description: 'Переводы за рубеж средств нерезидентов — как физических, так и юридических лиц — из недружественных стран со счетов брокеров и доверительных управляющих будут ограничены с 1 октября еще на полгода',
        //     img: img_news,
        // },
    ],
    isLoading: false,
    error: ''
}


export const newsSlice = createSlice({
    name: 'news',
    initialState: initialState,
    reducers: {
        
    }
})

export default newsSlice.reducer;