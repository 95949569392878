import { classNames } from 'shared/lib/classNames/classNames';
import { IPersonal } from 'shared/models/ContactModel';
import cls from './PersonalCard.module.css';

interface PersonalCardProps {
    className?: string;
    data: IPersonal;
}

export const PersonalCard: React.FC<PersonalCardProps> = (props) => {
    const { data, ...otherProps } = props;

    return (
        <div className={classNames(cls.personalCard, {}, ['card border-0 mb-4 mb-md-0'])} style={{maxWidth: "500px"}} {...otherProps}>
            <div className="row no-gutters">
            <div className="col-md-4 pr-5 pr-md-0 mr-5 mr-md-0">
                <img src={data.photo} className="card-img" alt="..."/>
            </div>
            <div className="col-md-8">
                <div className="card-body pt-md-0">
                <p className="card-title">{data.fio}</p>
                <p className="card-text"><b>{data.position}</b></p>
                <p className="card-text">E-mail: <a href={`mailto:${data.email}`}>{data.email}</a></p>
                </div>
            </div>
            </div>
        </div>
 );
}