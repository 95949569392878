import { ConsultationForm } from 'widgets/ConsultationForm/ui/ConsultationForm'
// import cls from './ConsultationRow.module.css'

export const ConsultationRow = () => {

 

  return (
    <div className="consultation-row container-fluid home-consult py-5 px-0" id='consultationForm'>
        <div className="container">
          <h2 className="text-white pb-3">Начнем с консультации?</h2>
          <p className="text-white sub-title col-md-8 col-xl-6 px-0 pb-4 mb-md-3">Наши услуги бесплатны для вас от первой консультации до получения страховки</p>
          <ConsultationForm />
        </div>
      </div>
  )
}
