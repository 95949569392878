import axios from "axios"



export const FormSubmitHandler = (formData: any) => {

    // const url = 'http://127.0.0.1:8000/api/v1/call_message'
    const url = 'https://api.scr-broker.ru/api/v1/call_message'
    const full_data = {...formData, type: 'Звонок' }
    axios.post(url, full_data)
        .then((res)=>{console.log(res)})
        .catch((err)=>{console.log(err)})
}