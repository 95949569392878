import { classNames } from 'shared/lib/classNames/classNames';
import { INewsCard } from 'shared/models/NewsModel';
import cls from './NewsCard.module.css';


interface NewsCardProps {
  classNames?: string;
  card: INewsCard;
}


export const NewsCard: React.FC<NewsCardProps> = (props) => {
  const { card, ...otherProps } = props;

  return (
    <div className={classNames(cls.newsCard, {}, ["col-lg-4 pb-4 pb-lg-5 mb-2"])} {...otherProps}>
        <p className="news-header pb-3">{card.title}</p>
        <p className="news-date pb-2">{card.date}</p>
        <p className="news-text pb-4">{card.description}</p>
        <img className="d-block w-100" src={card.img} alt=""/>
    </div>
 );
}
