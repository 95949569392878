type Mods = Record<string, boolean | string> 


export function classNames(cls: string, mods: Mods={}, anotherClasses: string[]=[]): string {

    return [
        cls,
        ...anotherClasses,
        ...Object.entries(mods)
            .filter(([className, value])=>Boolean(value))
            .map(([className])=>className)
    ].join(' ')
}