import { classNames } from 'shared/lib/classNames/classNames';
import cls from './ConsultationForm.module.css';
import { useForm } from 'react-hook-form'
import { useAppDispatch, useAppSelector } from 'shared/hooks/useAppDispatch/useAppDispatch'
import { CheckboxSlice } from 'shared/reducers/CheckboxReducer/CheckboxSlice'
import { Button, ButtonTheme } from 'shared/ui/Button/Button'
import { Checkbox } from 'shared/ui/Checkbox/Checkbox'
import { FormSubmitHandler } from '../lib/FormSubmitHandler';

export const ConsultationForm = (props) => {
    const { ...otherProps } = props;

    const {
        register, 
        formState: {
          errors,
          isValid,
        },
        handleSubmit,
        reset
      } = useForm({
        mode: 'onBlur'
      })
    
    
      const dispatch = useAppDispatch()
      const cheboxIsChecked = useAppSelector(state=>state.CheckboxReducer.checked)
      const phoneReg = /^(\+7|7|8)?[\s\-]?\(?[489][0-9]{2}\)?[\s\-]?[0-9]{3}[\s\-]?[0-9]{2}[\s\-]?[0-9]{2}$/gm
      
      const OnSumitHandler = (data) => {
        FormSubmitHandler(data)
        reset()
      }
      
    return (
        <form onSubmit={handleSubmit(OnSumitHandler)} {...otherProps} >
            <div className="row">
              <div className="col-lg-3 mb-3">
                <input type="text" 
                {...register(
                  'name', 
                  {
                    required: 'Поле обязательно для заполнения'
                  })} 
                  className="form-control box-shadow br-10" 
                  placeholder="Имя"/>
                  {
                    errors?.name && <p className={cls.error}>{errors?.name?.message}</p> 
                  }
              </div>
              <div className="col-lg-3 mb-3">
                <input 
                  type="text" 
                  {...register(
                    'phone', 
                  {
                    pattern: {
                      value: phoneReg,
                      message: 'Неправильно введен номер'
                    },
                    required: 'Поле обязательно для заполнения',

                  })} 
                  className="form-control box-shadow br-10" 
                  placeholder="+7 (999) 999-99-99"
                  />
                  {
                    errors?.phone && <p className={cls.error} >{errors?.phone?.message}</p> 
                  }
              </div>
              <div className="col-lg-3 mb-4">
                <input 
                  type="text" 
                  {
                    ...register('company', {
                      required: 'Поле обязательно для заполнения'
                    })
                  } 
                  className="form-control box-shadow br-10" 
                  placeholder="Название компании"/>
                  {
                    errors?.company && <p className={cls.error}>{errors?.company?.message}</p> 
                  }
              </div>
              <div className="col-lg-3 mb-4">
                <Button 
                  className={classNames(cls.btn, {}, [])} 
                  disabled={!cheboxIsChecked || !isValid} 
                  theme={ButtonTheme.RED}  
                >
                  Получить консультацию
                </Button>
              </div>
            </div>
            <div className={classNames(cls.checkboxContainer, {}, ["row justify-content-center"])}>
              <Checkbox onClick={()=>dispatch(CheckboxSlice.actions.toggleCheckbox())} id="agree" name="agree" text={
                <>Нажимая кнопку “Записаться”, Вы соглашаетесь <a href="/" className={cls.agreeLink}> с условиями обработки Персональных данных</a></>
              }/>
            </div>
          </form>
 );
}