import { useForm } from 'react-hook-form';
import { useAppDispatch, useAppSelector } from 'shared/hooks/useAppDispatch/useAppDispatch';
import { classNames } from 'shared/lib/classNames/classNames';
import {CheckboxSlice} from 'shared/reducers/CheckboxReducer/CheckboxSlice';
import { PopupSlice } from 'shared/reducers/PopupReducer/PopupSlice';
import { Button, ButtonTheme } from 'shared/ui/Button/Button';
import { Checkbox } from 'shared/ui/Checkbox/Checkbox';
import { sendMailConsultation } from '../api/sendMailConsultation';
import cls from './FormPopup.module.css';


export const FormPopup = (props) => {
    const { className } = props;
    const {hidden} = useAppSelector(state => state.PopupReducer) 
    const {togglePopup} = PopupSlice.actions;

    const dispatch = useAppDispatch()

    const {
        register, 
        formState: {
          errors,
          isValid,
        },
        handleSubmit,
        reset
      } = useForm({
        mode: 'onBlur'
      })
      
      const cheboxIsChecked = useAppSelector(state=>state.CheckboxReducer.checked)
      const phoneReg = /^(\+7|7|8)?[\s\-]?\(?[489][0-9]{2}\)?[\s\-]?[0-9]{3}[\s\-]?[0-9]{2}[\s\-]?[0-9]{2}$/gm
      const emailReg =  /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

      const OnSubmitHandle = (data) => {
        sendMailConsultation(data)
        reset()
      }

    return (
        <>
            <span className={cls.cross} onClick={()=>dispatch(togglePopup())}></span>
            <p className={cls.description}>
                Наши услуги бесплатны для вас
                от первой консультации до получения страховки
            </p>
            
            <form className={cls.form} onSubmit={
              handleSubmit(OnSubmitHandle)
            }>
            <div className="row">
              <div className="col-lg-6 mb-3">
                <input type="text" 
                {...register(
                  'name', 
                  {
                    required: 'Поле обязательно для заполнения'
                  })} 
                  className="form-control box-shadow br-10" 
                  placeholder="Имя"/>
                  {
                    errors?.phone && <p className={cls.error}>{errors?.name?.message}</p> 
                  }
              </div>
              <div className="col-lg-6 mb-3">
                <input 
                  type="text" 
                  {...register(
                    'phone', 
                  {
                    pattern: {
                      value: phoneReg,
                      message: 'Неправильно введен номер'
                    },
                    required: 'Поле обязательно для заполнения',

                  })} 
                  className="form-control box-shadow br-10" 
                  placeholder="+7 (999) 999-99-99"
                  />
                  {
                    errors?.phone && <p className={cls.error} >{errors?.phone?.message}</p> 
                  }
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6 mb-3">
                <input type="text" 
                {...register(
                  'email', 
                  {
                    required: 'Поле обязательно для заполнения',
                    pattern: {
                      value: emailReg,
                      message: 'Неверно введен адрес почты'
                    }
                  })} 
                  className="form-control box-shadow br-10" 
                  placeholder="Email"/>
                  {
                    errors?.email && <p className={cls.error}>{errors?.email?.message}</p> 
                  }
              </div>
              <div className="col-lg-6 mb-3">
                <input 
                  type="text" 
                  {...register(
                    'company', 
                  {
                    required: 'Поле обязательно для заполнения',

                  })} 
                  className="form-control box-shadow br-10" 
                  placeholder="Название компании"
                  />
                  {
                    errors?.company && <p className={cls.error} >{errors?.company?.message}</p> 
                  }
              </div>
            </div>
            <div className={classNames(cls.checkbox, {}, ["row"])}>
              <Checkbox id='popup_agree' name='popup_agree' onClick={()=>dispatch(CheckboxSlice.actions.toggleCheckbox())} text={
                <>
                  Нажимая кнопку “ Отправить”,
                  Вы соглашаетесь с <a href='/' className={cls.agreeLink}>условиями обработки персональных данных</a>
                </>
              } />
            </div>
                
                <Button disabled={!cheboxIsChecked || !isValid} className='col-lg-6 ' theme={ButtonTheme.GRAY}>
                    Отправить
                </Button>
            </form>
        </>
 );
}