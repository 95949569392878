import { createRoot } from 'react-dom/client';
import {BrowserRouter} from 'react-router-dom'
import './app/styles/normalize.css'
import './app/styles/style.css'
import './app/styles/media.css'


import App from './app/App';
import { Provider } from 'react-redux';
import { setupStore } from 'app/store/sotre';
const container = document.getElementById('root')!;
const root = createRoot(container);

root.render(
  <BrowserRouter>
    <Provider store={setupStore()}>
      <App />
    </Provider>
  </BrowserRouter>
);