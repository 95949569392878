import { serviceApi } from 'shared/api/ServiceApi';
import PopupReducer from 'shared/reducers/PopupReducer/PopupSlice';
import { combineReducers, configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import  ServiceReduser  from 'shared/reducers/ServiceReducer/ServiceSlice';
import  NewsReducer  from 'shared/reducers/NewsSlice';
import  ContactReducer  from 'shared/reducers/ContactsSlice';
import ReviewReduser from 'shared/reducers/ReviewSlice'
import CheckboxReducer from 'shared/reducers/CheckboxReducer/CheckboxSlice'

const rootReducer = combineReducers({
    NewsReducer,
    ServiceReduser,
    ContactReducer,
    ReviewReduser,
    PopupReducer,
    CheckboxReducer,
    [serviceApi.reducerPath]: serviceApi.reducer
})


export const setupStore = () => {
    return configureStore({
        reducer: rootReducer,
        middleware: (getDefaultMiddleware) => 
        getDefaultMiddleware().concat(serviceApi.middleware)
    })
}  

export type RootState = ReturnType<typeof rootReducer>  
export type AppStore = ReturnType<typeof setupStore>  
export type AppDispatch = AppStore['dispatch']  