import React, { FC, useState } from 'react'
import { Link, NavLink } from 'react-router-dom'
import { useAppDispatch } from 'shared/hooks/useAppDispatch/useAppDispatch'
import { PopupSlice } from 'shared/reducers/PopupReducer/PopupSlice'
import Logo  from '../../../../app/img/top_logo.svg'
import { classNames } from 'shared/lib/classNames/classNames'
import cls from './Header.module.css'

export const Header: FC = () => {

  const [menuBurger, setMenuBurger] = useState(false)
  const dispatch = useAppDispatch()

  const {togglePopup} = PopupSlice.actions;

  return (
    <header>
      <nav className="navbar navbar-expand-md navbar-light p-0">
        <div className="container align-items-center py-4">
          {/* <span className="phone d-block d-md-none ml-3">RU</span> */}
          <Link className="header-logo" to="/">
            <img className="logo" src={Logo}  alt="site logo click for reload site" title="" />
          </Link>
          <div className="d-flex flex-column position-relative">
            <div className="d-none d-md-flex align-items-center justify-content-between pl-2 pb-3">
              <a href="tel:+998712076556"><span className="phone text-nowrap">+7 (495) 967-0215</span></a>
              <a onClick={()=>dispatch(togglePopup())} className="top call-back-btn" type="button">Заказать звонок</a>
              {/* <span className="phone text-nowrap">RU</span> */}
            </div>            
            <button 
              className="navbar-toggler navbar-toggler-right text-right" 
              type="button" 
              onClick={() => setMenuBurger(prev => !prev)}
              data-toggle="collapse"data-target="#navbarToggler" 
              aria-controls="navbarToggler" 
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className={classNames('', {
              [cls.mobileNav]: window.screen.width <= 765,
              'collapse': !menuBurger,
              'navbar-collapse': !menuBurger
            }, [])} id="navbarToggler">
              <ul className="navbar-nav mr-3 mr-md-0">
                <li className={classNames(cls.item, {}, ["nav-item mr-xl-4"])}>
                  <NavLink className="header__link text-nowrap pr-md-4 pr-lg-5" to="/">Главная страница</NavLink>
                </li>
                <li className={classNames(cls.item, {}, ["nav-item mr-xl-4"])}>
                  <NavLink className="header__link text-nowrap pr-md-4 pr-lg-5" to="/service/1">Услуги</NavLink>
                </li>
                <li className={classNames(cls.item, {}, ["nav-item mr-xl-4"])}>
                  <NavLink className="header__link text-nowrap pr-md-4 pr-lg-5" to="/about">О нас</NavLink>
                </li>
                {/* <li className="nav-item mr-xl-4">
                  <NavLink className="header__link text-nowrap pr-md-4 pr-lg-5" to="/news">Новости</NavLink>
                </li> */}
                <li className={classNames(cls.item, {}, ["nav-item mr-xl-4"])}>
                  <NavLink className="header__link text-nowrap" to="/contacts/russia">Контакты</NavLink>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </nav>
    </header>
  )
}
