import { NavLink } from 'react-router-dom';
import { useAppSelector } from 'shared/hooks/useAppDispatch/useAppDispatch';
import { classNames } from 'shared/lib/classNames/classNames';
import cls from './CountryRow.module.css';

interface CountryRowProps {
    // className?: string;
}

export const CountryRow: React.FC<CountryRowProps> = (props) => {
    // const { className } = props;

    const {countries} = useAppSelector(state => state.ContactReducer)

    return (
        <div className={classNames(cls.countryRow, {}, ["row-item-box d-flex flex-nowrap pt-3 pb-5 px-md-3"])}>
            {
                countries.map(({country, slug_country_name}, index)=>(
                    <NavLink key={"country_link_"+index} to={`/contacts/${slug_country_name}`} className="offise-btn box-shadow br-10 mx-1 mx-md-2 px-4 py-3 py-md-4" type="button">
                        {country}
                    </NavLink>
                ))
            }
        </div>
 );
}