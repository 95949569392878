import React, { FC } from 'react'
import { NewsSlideProps } from 'shared/models/NewsModel'
import { NewsCard } from '../../../enteties/NewsCard'




export const NewsSlide: FC<NewsSlideProps> = (props) => {
  const {data, ...otherProps} = props

  return (
    <div className='row' {...otherProps} >
        {
          data.map((card, index)=>(
            <NewsCard 
              card={card}
              key={'news_card_'+index}
            ></NewsCard>
          ))
        }
    </div>
  )
}
