import { classNames } from 'shared/lib/classNames/classNames';
import cls from './InfoPage.module.css';


interface InfoPageProps {
className?: string;
}

export const InfoPage: React.FC<InfoPageProps> = (props) => {
    const { className } = props;

    return (
    <main className={classNames(cls.infoPage, {}, [className ?? '' ])}>
        <div className="container pt-5">
            <p className="pb-4">
            ООО «Страховой брокер «Сосьете де Куртаж Ре», осуществляет посредническую деятельность в качестве страхового брокера на основании <a style={{textDecoration: 'underline', color: '#CC1D0E'}} target='_blank' href={require("../../shared/assets/docs/License 4031.pdf")} rel="noreferrer"> Лицензии СБ № 4031 от 12.11.2015</a>, выданной <a style={{textDecoration: 'underline', color: '#CC1D0E'}} target='_blank' href={'https://www.cbr.ru/'} rel="noreferrer"> Центральным Банком Российской Федерации</a> без ограничения срока действия.</p>
            <p className="pb-4">
                ОГРН: 1027700084851 <br />
                ИНН: 7701248965
            </p>
            <p className="">ООО «Страховой брокер «Сосьете де Куртаж Ре» с 18 мая 2017 г. является членом Ассоциации Профессиональных Страховых Брокеров <a style={{textDecoration: 'underline', color: '#CC1D0E'}} target='_blank' href={require("../../shared/assets/docs/(АПСБ).pdf")} rel="noreferrer">(АПСБ)</a>.</p>
            <p className="pb-4">Надзор за деятельностью ООО «Страховой брокер «Сосьете де Куртаж Ре», Россия осуществляет Ценральный Банк Российской Федерации.</p>

            <p className=""><a style={{textDecoration: 'underline', color: '#CC1D0E'}} target='_blank' href={require("../../shared/assets/docs/Info.pdf")} rel="noreferrer">Информация о Страховом брокере</a></p>
            <p className=""><a style={{textDecoration: 'underline', color: '#CC1D0E'}} target='_blank' href={require("../../shared/assets/docs/Rekvizit.pdf")} rel="noreferrer">Реквизитный лист Страхового брокера</a></p>
            <p className=""><a style={{textDecoration: 'underline', color: '#CC1D0E'}} target='_blank' href={require("../../shared/assets/docs/7701248965_20170126_122851.pdf")} rel="noreferrer">Выписка ЕГРЮЛ Страхового брокера</a></p>
            <p className=""><a style={{textDecoration: 'underline', color: '#CC1D0E'}} target='_blank' href={require("../../shared/assets/docs/Anketa.pdf")} rel="noreferrer">Анкета Страхового брокера по 115-ФЗ</a></p>
            <p className="pb-4 "><a style={{textDecoration: 'underline', color: '#CC1D0E'}} target='_blank' href={require("../../shared/assets/docs/Report.pdf")} rel="noreferrer">Отчетность</a></p>

            <p className=""><b>Условия сотрудничества</b></p>

            <p className=""><a style={{textDecoration: 'underline', color: '#CC1D0E'}} target='_blank' href={require("../../shared/assets/docs/Анкета Клиента по 115-ФЗ.pdf")} rel="noreferrer">Анкета Клиента по 115-ФЗ</a></p>
            <p className=""><a style={{textDecoration: 'underline', color: '#CC1D0E'}} target='_blank' href={require("../../shared/assets/docs/Polozhenie.pdf")} rel="noreferrer">Положение об основах перестраховочной деятельности</a></p>
            <p className=""><a style={{textDecoration: 'underline', color: '#CC1D0E'}} target='_blank' href={require("../../shared/assets/docs/Standart.pdf")} rel="noreferrer">Базовый стандарт защиты прав и интересов физических и юридических лиц</a></p>
            <p className=""><a style={{textDecoration: 'underline', color: '#CC1D0E'}} target='_blank' href={require("../../shared/assets/docs/Info_sposob.pdf")} rel="noreferrer">Способы защиты прав получателей страховых услуг, в том числе, о досудебных способах урегулирования споров и процедуры медиации</a></p>
            <p className="pb-4 "><a style={{textDecoration: 'underline', color: '#CC1D0E'}} target='_blank' href={require("../../shared/assets/docs/Trebovaniya.pdf")} rel="noreferrer">Требованиями и рекомендациями к содержанию обращения получателя финансовых услуг</a></p>

            <p className="pb-4">ООО «Страховой брокер «Сосьете де Куртаж Ре», как независимый страховой посредник, не участвует в капитале Страховщиков и не осуществляет страхование в интересах Страховщиков. В Капитале ООО «Страховой брокер «Сосьете де Куртаж Ре» отсутствует доля Страховщиков.
            </p>
            <p className="pb-4">ООО «Страховой брокер «Сосьете де Куртаж Ре» осуществляет прием обращений получателей финансовых услуг по следующим адресам: <br /> 
            Электронная почта: <a style={{textDecoration: 'underline'}} className='' href="mailto:scr@scr-broker.com">scr@scr-broker.com</a><br />
            Почтовый адрес: 123610, Москва, Краснопресненская набережная, д.12. подъезд 3, офис 710<br />
            Минимальный срок, на который может быть заключен Договор страхования или перестрахования составляет 6 (шесть) месяцев.
            </p>
            <p className="pb-4">Нашими Клиентами по прямому страхованию являются: ВТБ-Лизинг, Гражданские самолеты Сухого, ОГК-5, ТГК-9, ФСК ЕЭС, Московский бизнес-инкубатор, Финансовая лизинговая компания, Аэроэкспресс, Группа компаний Интерстатус
            </p>
        </div>
    </main>
    );
    }