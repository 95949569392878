import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PopupState } from "shared/models/PopupModel";


export const enum PopupVariants  {
    START = 'start',
    FORM = 'form'
}

const initialState: PopupState = {
    hidden: true,
    variant: PopupVariants.START
}


export const PopupSlice = createSlice({
    name: 'popup',
    initialState: initialState,
    reducers: {
        togglePopup(state){
            const body = document.body
            if(state.hidden){
                body.style.overflow = 'hidden'
            }else{
                body.style.overflow = 'auto'
            }
            state.hidden = !state.hidden
        }, 
        setPopupVariant(state, action: PayloadAction<PopupVariants>){
            state.variant = action.payload
        }
    }
})

export default PopupSlice.reducer;