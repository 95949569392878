import { PopupSlice } from 'shared/reducers/PopupReducer/PopupSlice';
// export const fetchServices = () => async (dispatch: AppDispatch) =>  {
//     try {
//         dispatch(ServiceSlice.actions.servicesFetching())
//         // const url = 'https://api.scr-broker.ru/api/v1/services_list'
//         const url = 'http://localhost:8000/api/v1/services_list'
//         const response = await axios.get<IService[]>(url)
//         console.log(response)
//         dispatch(ServiceSlice.actions.servicesFetchingSuccess(response.data))
//     } catch (e) {
//         dispatch(ServiceSlice.actions.servicesFetchingError('errore'))
//     }
// }

import { AppDispatch } from "app/store/sotre";
import { PopupVariants } from "./PopupSlice";

export const openPopup = (variant: PopupVariants) => async (dispatch: AppDispatch) =>  {
    dispatch(PopupSlice.actions.setPopupVariant(variant))
}

