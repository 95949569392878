import React, { FC } from 'react'
import { Link, NavLink, useLocation, useNavigate } from 'react-router-dom'
import { serviceApi } from 'shared/api/ServiceApi'
import Logo  from '../../../../app/img/top_logo.svg'


export const Footer: FC = () => {

  const {data: services} = serviceApi.useFetchAllServicesQuery('')
  const navigate = useNavigate()

  return (
    <footer>
      <div className="container text-center text-lg-left py-5">
        <div className="row">
          <div className="col-lg-4">
            <Link className="footer-logo" to="/">
              <img className="logo mb-3 mb-md-4" src={Logo} alt="site logo click for reload site" title=""/>
            </Link>

            <p className=" pb-4 mb-lg-3">Лицензия ЦБ РФ без ограничения срока действия на осуществление посреднической деятельности в качестве страхового брокера СБ № 4031 от 12.11.2015</p>
          </div>
          <div className="col-lg-4 d-flex flex-column">
            <p className="footer__link  mb-3">Услуги</p>
            {
              services?.slice(0, 4).map(({id, title}, index) => (
                <NavLink 
                onClick={(event)=>{
                  window.scrollTo(0, 0)
                }} 
                to={`/service/${id}`} 
                className="footer__link-sublink  mb-3"
                key={'footer_link'+id}
                >
                  {title}
                </NavLink>
              ))
            }
          </div>
          <div className="col-lg-4 d-flex flex-column">
          {
              services?.slice(4).map(({id, title}, index) => (
                <NavLink 
                onClick={()=>window.scrollTo(0, 0)} 
                to={`/service/${id}`} 
                className="footer__link-sublink  mb-3"
                key={'footer_link'+id}
                >
                  {title}
                </NavLink>
              ))
            }
          </div>
        </div>
        <div className="row">
          <div className="col-lg-4">
            <p className="text-color-red d-none d-lg-block">Все права защищены. 2022</p>
          </div>
          <div className="col-lg-4 d-flex justify-content-between pr-lg-5">
            <NavLink onClick={()=>{window.scrollTo(0, 0) }} to="/" className="footer__link  mb-3">Главная страница</NavLink>
            <NavLink onClick={()=>{window.scrollTo(0, 0) }} to="/about" className="footer__link  mb-3">О нас</NavLink>
            {/* <NavLink onClick={()=>{window.scrollTo(0, 0) }} to="/news" className="footer__link  mb-3">Новости</NavLink> */}
            <NavLink onClick={()=>{window.scrollTo(0, 0) }} to="/info" className="footer__link  mb-3">Юридическая информация</NavLink>
          </div>
          <div className="col-lg-4 d-flex flex-column">
            <p className="text-color-red d-lg-none mb-2">Все права защищены. 2022</p>
            <a href="#" className="text-color-red">Политика конфиденциальности</a>
          </div>
        </div>
      </div>
    </footer>
  )
}
