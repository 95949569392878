import { OfficeCard } from 'enteties/OfficeCard';
import { PersonalCard } from 'enteties/PersonalCard/ui/PersonalCard';
import { useParams } from 'react-router-dom';
import { useAppSelector } from 'shared/hooks/useAppDispatch/useAppDispatch';
import { classNames } from 'shared/lib/classNames/classNames';
import cls from './ContactContent.module.css';

interface ContactContentProps {
    className?: string;
}

export const ContactContent: React.FC<ContactContentProps> = (props) => {
    // const { className } = props;

    const params = useParams()
    const contact = useAppSelector(state=> state.ContactReducer.countries
        .find(({slug_country_name})=> slug_country_name===params.country_slug ))
    

    return (
        <div className={classNames(cls.contactContent, {}, ['container'])}>
                <div className={cls.offices}>
                    {
                        contact?.offices?.map((data, index)=>(
                            <OfficeCard data={data} key={"office_card_"+index} className={cls.officeCard}/>
                        ))
                    }
                </div>
                <h2 className="text-color-red py-4 py-md-5">Контактные лица</h2>
                <div className="d-flex flex-column flex-md-row justify-content-between mb-5">
                    {
                        contact?.personals?.map((data, index)=> (
                            <PersonalCard key={"personal_card_"+index} data={data}/>
                        ))
                    }
                </div>
            </div>
 );
}