import { HtmlHTMLAttributes, useState } from 'react';
import { classNames } from 'shared/lib/classNames/classNames';
import cls from './Checkbox.module.css';

interface CheckboxProps extends HtmlHTMLAttributes<HTMLInputElement> {
    className?: string;
    id: string;
    name: string;
    text:  any;
}

export const Checkbox: React.FC<CheckboxProps> = (props) => {
    const { className, id, text, ...otherProps} = props;

    const [isChecked, setIsChecked] = useState(false)

    return (
        <label htmlFor={id} className={classNames(cls.label, {[cls.checked]: isChecked}, [])}>
            <input 
                type={'checkbox'} 
                id={id} 
                className={classNames(cls.checkbox, {}, [])} 
                {...otherProps} 
                onChange={()=>setIsChecked(!isChecked)}
                />
            {text}
        </label>
 );
}