import { createSlice } from '@reduxjs/toolkit';
import { ReviewState } from "shared/models/ReviewsModel";


const initialState: ReviewState = {
    error: '',
    isLoading: false,
    reviews: [
        // {
        //     key: 1,
        //     author: 'Татьяна Иванова',
        //     date: '12.12.2020',
        //     rating: 5,
        //     company: 'Отзыв от СтильСтиль',
        //     text: 'Вам придется отложить все дела, связанные с работой и самостоятельно обращаться во все компании.  Вам придется отложить все дела, связанные с работой и самостоятельно обращаться во все компании. '
        // },
        // {
        //     key: 2,
        //     author: 'Татьяна Иванова',
        //     date: '12.12.2020',
        //     rating: 4,
        //     company: 'Отзыв от СтильСтиль',
        //     text: 'Вам придется отложить все дела, связанные с работой и самостоятельно обращаться во все компании.  Вам придется отложить все дела, связанные с работой и самостоятельно обращаться во все компании. '
        // },
        // {
        //     key: 3,
        //     author: 'Татьяна Иванова',
        //     date: '12.12.2020',
        //     rating: 3,
        //     company: 'Отзыв от СтильСтиль',
        //     text: 'Вам придется отложить все дела, связанные с работой и самостоятельно обращаться во все компании.  Вам придется отложить все дела, связанные с работой и самостоятельно обращаться во все компании. '
        // },
        // {
        //     key: 4,
        //     author: 'Татьяна Иванова',
        //     date: '12.12.2020',
        //     rating: 2,
        //     company: 'Отзыв от СтильСтиль',
        //     text: 'Вам придется отложить все дела, связанные с работой и самостоятельно обращаться во все компании.  Вам придется отложить все дела, связанные с работой и самостоятельно обращаться во все компании. '
        // },
        // {
        //     key: 5,
        //     author: 'Татьяна Иванова',
        //     date: '12.12.2020',
        //     rating: 1,
        //     company: 'Отзыв от СтильСтиль',
        //     text: 'Вам придется отложить все дела, связанные с работой и самостоятельно обращаться во все компании.  Вам придется отложить все дела, связанные с работой и самостоятельно обращаться во все компании. '
        // },
        // {
        //     key: 5,
        //     author: 'Татьяна Иванова',
        //     date: '12.12.2020',
        //     rating: 0,
        //     company: 'Отзыв от СтильСтиль',
        //     text: 'Вам придется отложить все дела, связанные с работой и самостоятельно обращаться во все компании.  Вам придется отложить все дела, связанные с работой и самостоятельно обращаться во все компании. '
        // },
    ]
}


const ReviewSlice = createSlice({
    name: 'reviews',
    initialState: initialState,
    reducers: {

    }
})

export default ReviewSlice.reducer;