import { HTMLAttributes } from 'react';
import { classNames } from 'shared/lib/classNames/classNames';
import cls from './Bullet.module.css';

interface BulletProps extends HTMLAttributes<HTMLSpanElement> {
    className: string;
    children: any;
}

export const Bullet: React.FC<BulletProps> = (props) => {
    const { className, children, ...otherProps } = props;

    return (
        <span className={classNames(cls.bullet, {}, [className, 'page-number mx-3'])}  {...otherProps} >{children}</span>

 );
}