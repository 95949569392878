import { FormPopup } from 'enteties/FormPopup/ui/FormPopup';
import { StartPopup } from 'enteties/StartPopup/ui/StartPopup';
import { useAppDispatch, useAppSelector } from 'shared/hooks/useAppDispatch/useAppDispatch';
import { classNames } from 'shared/lib/classNames/classNames';
import { PopupSlice, PopupVariants } from 'shared/reducers/PopupReducer/PopupSlice';
import { Button, ButtonTheme } from 'shared/ui/Button/Button';
import cls from './Popup.module.css';

interface PopupProps {
    className?: string;
}

export const Popup: React.FC<PopupProps> = (props) => {
    const { ...otherProps } = props;

    const {hidden, variant} = useAppSelector(state => state.PopupReducer) 
    const dispatch = useAppDispatch()

    const {togglePopup} = PopupSlice.actions;

    return (
        <div className={classNames(cls.wrapper, {[cls.hidden]: hidden}, [])} {...otherProps}>
            <div className={cls.popup}>
                {
                    variant === PopupVariants.START
                    ?
                    <StartPopup />
                    :
                    variant === PopupVariants.FORM
                    ? <FormPopup />
                    :
                    <p>Модальное окно не найдено</p>
                }
            </div>
        </div>
 );
}