import { useEffect, useState } from 'react';
import { classNames } from 'shared/lib/classNames/classNames';
import { EffectFade, Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide, useSwiper } from 'swiper/react';
import "swiper/css/effect-fade";

import cls from './BannerSlider.module.css';
import { serviceApi } from 'shared/api/ServiceApi';
import { useAppDispatch } from 'shared/hooks/useAppDispatch/useAppDispatch';
import { ServiceSlice } from 'shared/reducers/ServiceReducer/ServiceSlice';
import { delay } from '@reduxjs/toolkit/dist/utils';

interface BannerSliderProps {
    className?: string;
}



export const BannerSlider: React.FC<BannerSliderProps> = (props) => {
    // const { className } = props;

    // const [activeBullet, setActiveBullet] = useState(0)
    const [activeIndex, setActiveIndex] = useState(0)

    const { data }  = serviceApi.useFetchAllServicesQuery('')

    const dispatch = useAppDispatch()

    function onChangeHandler(el: any){
        dispatch(ServiceSlice.actions.setActiveServiceBanner(data?.find(({id}, index)=> index===el)?.banner))
        setActiveIndex(el)
    }

    useEffect(() => {
        const intervalId = setInterval(() => {
            if(data && activeIndex < 3){
                onChangeHandler(activeIndex+1)
            }else {
                onChangeHandler(0)
            }
        }, 3000);
    
        return () => clearInterval(intervalId);
      }, [activeIndex, data]);
    
    

    return (
        <Swiper className={classNames(cls.bannerSlider, {}, [])}
            wrapperClass={cls.wrapper}
            effect="fade"
            modules={[EffectFade, Pagination, Navigation]}
            navigation={true}
            pagination={{
                // clickable: true,
                // enabled: true,
                // bulletClass: cls.bullet,
                // el: '.pagination',
                // bulletActiveClass: cls.active
            }}
            autoplay={true}
            onSlideChange = {(el)=>onChangeHandler(el.activeIndex)}
            
        >
            <div className={cls.wrapper}>
                {
                    data && data.slice(0, 4).map(({id, title})=>(
                        <SwiperSlide key={'swiper_banner_slide_'+id} className={classNames(cls.slide, 
                            {
                                [cls.hidden]: id!==activeIndex+1,
                                [cls.activeSlide]: id===activeIndex+1
                            }, 
                            [])}

                        >
                            <p>{title}</p> 
                        </SwiperSlide>
                    ))
                }
                
            </div>
            <ol className={classNames(cls.pagination, {}, ['pagination'])}>
                {
                    data && data.slice(0, 4).map(({id}, index)=>(
                        <li 
                            key={'swiper_banner_pagination_'+id} 
                            className={classNames(cls.bullet, {[cls.active]: id===activeIndex+1}, [])} 
                            onClick={()=>onChangeHandler(index)}
                        ></li>
                    ))
                }
            </ol>
        </Swiper>
 );
}