import React from 'react'
import { Outlet } from 'react-router-dom'
import { CountryRow } from 'widgets/CountryRow/CountryRow'
import { ConsultationRow } from '../../../../widgets/ConsultationRow'
import { MapRow } from '../../../../widgets/MapRow'

export const ContactPage = () => {
  return (
    <main>
      <div className="banner-about-us container-fluid px-0 mb-3">
        <div className="container py-5">
          <div className="place-devide py-5 mb-lg-5 my-xl-5"></div>
          <div className="place-devide pt-5 pb-xl-4 mb-lg-5"></div>
        </div>
      </div>
      <CountryRow />

      <Outlet />
      <ConsultationRow />
      <MapRow />
    </main>
  )
}
