import React, {FC} from 'react'
import { Footer } from '../../../Footer'
import { Header } from '../../../Header'
import {Outlet} from 'react-router-dom'
import { Popup } from 'features/Popup/ui/Popup'

export const Layout: FC  = ({}) => {
  return (
    <div className={'wrapper'}>
        <Header />
        <Outlet />
        <Footer />
        <Popup />
    </div>
  )
}
