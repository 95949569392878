import { BannerSlider } from 'features/BannerSlider/ui/BannerSlider';
import { useAppDispatch, useAppSelector } from 'shared/hooks/useAppDispatch/useAppDispatch';
import { classNames } from 'shared/lib/classNames/classNames';
import { PopupSlice } from 'shared/reducers/PopupReducer/PopupSlice';
import cls from './BannerWithSlider.module.css';
import { serviceApi } from 'shared/api/ServiceApi';
import { useEffect, useState } from 'react';
import { IService } from 'shared/models/ServicesModel';
import { ServiceSlice } from 'shared/reducers/ServiceReducer/ServiceSlice';

interface BannerWithSliderProps {
    className?: string;
}

export const BannerWithSlider: React.FC<BannerWithSliderProps> = (props) => {
    // const { className } = props;

    // const {hidden} = useAppSelector(state => state) 
    const {togglePopup} = PopupSlice.actions
    const dispatch = useAppDispatch()
    const { activeServiceBanner } = useAppSelector(state => state.ServiceReduser) 
    const { data, isSuccess, isLoading, isFetching }  = serviceApi.useFetchAllServicesQuery('')


    useEffect(()=>{
        dispatch(ServiceSlice.actions.setActiveServiceBanner(data?.find((_, index)=> index===0)?.banner))
    },[isSuccess, isLoading, isFetching])

    return (
        <div className={classNames(cls.bannerWithSlider, {}, ['bg-slider'])}>
                <div className={'carousel-inner'}>
                    <div className="carousel-item active">
                        <div 
                            style={{ backgroundImage: `url(${activeServiceBanner})`, color: '#000'  }}
                            className={classNames(cls.slideContent, {}, ["container-fluid slide-1"])}
                            >
                            <div className="container-lg pt-4 pt-md-5">
                                <h1 className="col-md-9 pb-4 px-0">Страховой брокер для бизнеса</h1>
                                <p className="col-md-9 col-lg-8 d-none d-md-block pb-4 px-0 mb-3">Работаем со всеми: от малого бизнеса  до крупнейших корпораций </p>
                                <button onClick={()=>dispatch(togglePopup())} className="red-btn btn text-nowrap br-10 mb-3 mb-md-auto mx-3 mx-md-0 position-relative" type="button">Получить консультацию</button>
                            </div>
                            <BannerSlider />
                        </div>
                    </div>
                </div>
        </div>
 );
}