import { NavLink, useLocation } from 'react-router-dom';
import { serviceApi } from 'shared/api/ServiceApi';
import { classNames } from 'shared/lib/classNames/classNames';

import cls from './ServicesRow.module.css';
import { useEffect, useState } from 'react';

interface ServicesRowProps {
    className?: string;
    activeLink?: string;
}

export const ServicesRow: React.FC<ServicesRowProps> = (props) => {
    const {isLoading, data: services} = serviceApi.useFetchAllServicesQuery('')
    const [activeLink, setActiveLink] = useState('')
    const location = useLocation()


    useEffect(()=>{
      setActiveLink(location.pathname.split('/')[2])
    }, [location.pathname])

    

    return (
    <div className={classNames(cls.servicesRow, {}, ['services-row top row-item-box d-flex flex-md-nowrap pb-md-5'])}>
        <div className="d-flex flex-md-nowrap flex-row justify-content-start w-100 overflow-auto pb-md-5">
          {
            isLoading
            ?
            <p>Loading...</p>
            :
            services && services.map(({ icon, id, shortcut})=>(
                <NavLink 
                  to={'/service/'+id} 
                  key={'serivce_row_link_'+id}  
                  className={classNames(cls.card, {[cls.active]: id.toString() === activeLink}, ['box px-md-1'])}
                  >
                  <div className={classNames(cls.cardBody, {}, ["item-box-body box-shadow br-10 p-2 p-md-4"])}>
                    <span className={classNames(cls.icon, {}, [`mb-md-4`])} style={{background: `${icon})`}} dangerouslySetInnerHTML={{__html: icon}} >

                    </span>
                    <p className="d-none d-md-block text-center">{shortcut}</p>
                  </div>
              </NavLink> 
              ))
            }
        </div>
    </div>
 );
}